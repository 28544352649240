// Generated by Framer (6f17e57)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Pattern from "https://framerusercontent.com/modules/2rGdY3xNPdGAC1LGc2Ew/gQcpGdBaDKqalAQX5HN3/Pattern.js";
const PatternFonts = getFonts(Pattern);

const cycleOrder = ["dqMwJ8e8v"];

const variantClassNames = {dqMwJ8e8v: "framer-v-1u612fr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "dqMwJ8e8v", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "dqMwJ8e8v", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-JXAx1", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1u612fr", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dqMwJ8e8v"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}><motion.div className={"framer-brxu5v-container"} layoutDependency={layoutDependency} layoutId={"c7txqoz0S-container"} transition={transition}><Pattern back={"rgba(0, 0, 0, 0)"} diagonal direction={"left"} duration={5} front={"rgba(255, 255, 255, 0.3)"} height={"100%"} id={"c7txqoz0S"} layoutId={"c7txqoz0S"} patternType={"paper"} radius={0} scale={30} shouldAnimate={false} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-JXAx1 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JXAx1 .framer-fx4a4r { display: block; }", ".framer-JXAx1 .framer-1u612fr { height: 760px; overflow: hidden; position: relative; width: 1200px; }", ".framer-JXAx1 .framer-brxu5v-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 760
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const Framerx_9zUYtKp: React.ComponentType<Props> = withCSS(Component, css, "framer-JXAx1") as typeof Component;
export default Framerx_9zUYtKp;

Framerx_9zUYtKp.displayName = "Pattern";

Framerx_9zUYtKp.defaultProps = {height: 760, width: 1200};

addFonts(Framerx_9zUYtKp, [...PatternFonts])